import React, { useMemo } from 'react';
import { Box, CircularProgress, Typography, Grid } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const CircularProgressWithLabel = ({ value }) => {

  const getColor = (value) => {
    if (value < 10) return 'error.main'; // Rojo
    if (value > 10 && value <= 45) return 'warning.main'; // Amarillo
    if (value > 45 && value <= 100) return 'success.main'; // Verde
    return 'error.main'; // Valor por defecto en caso de que sea menor a 30 90%
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" 
      value={value}
      sx={{ color: getColor(value) }}  />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const ViewDiscos = ({ data = [], loading = false }) => {
  // Función para agrupar los datos por instance_id y asignar "claro" a cuenta si instance_id inicia con "mi-"
  const groupDataByInstanceId = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const { instance_id } = item;

      // Asigna "claro" a la columna cuenta si instance_id empieza con "mi-"
      if (instance_id.startsWith('mi-') && !item.cuenta) {
        item.cuenta = 'claro';
      }

      if (!acc[instance_id]) {
        acc[instance_id] = {
          ...item,
          details: [{ disco: item.disk, tamano: item.total_size, used_space: item.used_space, free_space:item.free_space, free_percent:item.free_percent }]
        };
      } else {
        acc[instance_id].details.push({ disco: item.disk, tamano: item.total_size, used_space: item.used_space, free_space:item.free_space, free_percent:item.free_percent });
      }
      return acc;
    }, {});
    return Object.values(groupedData);
  };

  const groupedData = useMemo(() => groupDataByInstanceId(data), [data]);

  const columns = useMemo(() => [
    {
      accessorKey: 'cuenta',
      header: 'Cuenta',
      size: 150,
      filterVariant: 'multi-select',
      filterSelectOptions: groupedData.map(item => item.cuenta).filter((value, index, self) => self.indexOf(value) === index),
    },
    {
      accessorKey: 'instance_id',
      header: 'Instance ID',
      size: 200,
    },
    {
      accessorKey: 'instance_name',
      header: 'Instance Name',
      size: 200,
      enableGrouping: false,
    },
    // {
    //   accessorKey: 'producto',
    //   header: 'Producto',
    //   size: 200,
    //   filterVariant: 'multi-select',
    //   filterSelectOptions: groupedData.map(item => item.producto).filter((value, index, self) => self.indexOf(value) === index),
    // },
  ], [groupedData]);

  const table = useMaterialReactTable({
    columns,
    data: groupedData,
    enableGrouping: true,
    initialState: {
      showColumnFilters: true,
      density: 'compact',
    },
    renderDetailPanel: ({ row }) => (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1}>
          {row.original.details.map((entry, index) => (
            <React.Fragment key={index}>
              <Grid item xs={2}>
                <Typography variant="body2">Disco:</Typography>
                <Typography variant="caption">{(entry.disco)}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">Tamaño:</Typography>
                <Typography variant="caption">{entry.tamano} GB</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">Espacio usado:</Typography>
                <Typography variant="caption">{entry.used_space} GB</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">Espacio Libre:</Typography>
                <Typography variant="caption">{entry.free_space} GB</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Porcentaje Libre:</Typography>
                <CircularProgressWithLabel value={entry.free_percent} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    ),
  });

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialReactTable table={table} />
      )}
    </Box>
  );
};

export default ViewDiscos;
