import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Modal,
  Typography,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';

interface GraphReportProps {
  data: Array<{
    value: number;
    name: string;
    event_type?: number;
    event_type_desc?: string;
    [key: string]: any;
  }>;
  type: string;
}

// Formatear la fecha en formato DD/MM/YYYY
const formatDate = (dateString: string) => {
  if (dateString.length === 8) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${day}/${month}/${year}`;
  }

  const date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  return 'Fecha inválida';
};

// Tooltip personalizado
const CustomTooltip = ({ payload }: any) => {
  if (payload && payload.length) {
    const { name, value, event_type, event_type_desc } = payload[0].payload;
    const formattedDate = formatDate(name);

    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
        <p>{`Fecha: ${formattedDate}`}</p>
        <p>{`Envios: ${value}`}</p>
        {event_type !== undefined && <p>{`Tipo de Evento: ${event_type}`}</p>}
        {event_type_desc && <p>{`Descripción: ${event_type_desc}`}</p>}
      </div>
    );
  }
  return null;
};

// Componente Modal para detalles
const DetailModal = ({ open, handleClose, data }) => (
  <Modal open={open} onClose={handleClose}>
    <Box sx={{ p: 4, bgcolor: 'background.paper', borderRadius: '8px', outline: 'none' }}>
      <Typography variant="h6">Detalles del Envío</Typography>
      {data && (
        <>
          <Typography variant="body1">{`Fecha: ${data.name}`}</Typography>
          <Typography variant="body1">{`Envíos: ${data.value}`}</Typography>
          <Typography variant="body1">{`Tipo de Evento: ${data.event_type !== undefined ? data.event_type : 'N/A'}`}</Typography>
          <Typography variant="body1">{`Descripción: ${data.event_type_desc ? data.event_type_desc : 'N/A'}`}</Typography>
        </>
      )}
      <Button onClick={handleClose}>Cerrar</Button>
    </Box>
  </Modal>
);

const GraphReport: React.FC<GraphReportProps> = ({ data, type }) => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  if (type !== 'line') return null;

  // Calcular el total de envíos
  const totalEnvios = data.reduce((total, row) => total + row.value, 0);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5} container alignItems="center" justifyContent="center" style={{ display: 'flex' }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Envíos</TableCell>
                <TableCell>Tipo de Evento</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(row.name)}</TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>{row.event_type !== undefined ? row.event_type : 'N/A'}</TableCell>
                  <TableCell>{row.event_type_desc ? row.event_type_desc : 'N/A'}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedData(row);
                        setOpen(true);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} md={7}>
        <Box sx={{ height: 400, marginLeft: 4 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} labelStyle={{ color: '#333' }} itemStyle={{ color: '#333' }} />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="table of totals">
            <TableHead>
              <TableRow>
                <TableCell>Total de Envíos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{totalEnvios}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <DetailModal open={open} handleClose={() => setOpen(false)} data={selectedData} />
    </Grid>
  );
};

export default GraphReport;
