import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import fetchData from '../../app/FetchData.js';
import AppLoading from '../../app/AppLoading.tsx';
import GraphReport from './graphreport.tsx';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'informes';

const cardDetails = [
  { title: "Enviados", action: "correos-enviados-claro-basic", type: 'line' },
  { title: "Abiertos", action: "correos-abiertos-claro", type: 'line' },
  { title: "Entregados", action: "correos-entregados-claro", type: 'line' },
  { title: "Fallidos", action: "correos-fallidos-claro", type: 'line' },
  { title: "Rebotados", action: "correos-rebotados-claro", type: 'line' },
  { title: "Redireccion Fallida", action: "correos-enviofallido-claro", type: 'line' },
  { title: "Retraso entrega", action: "otra-accion", type: 'line' },
  { title: "Click", action: "correos-click", type: 'line' },
  { title: "Queja", action: "correos-complaint", type: 'line' },
  { title: "Rebote", action: "otra-accion", type: 'line' }
];

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ViewEventosClaro = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [timeFilterTab, setTimeFilterTab] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTimeFilterChange = (event, newValue) => {
    setTimeFilterTab(newValue);
  };

  const getTimeFilter = () => {
    switch (timeFilterTab) {
      case 0: return 'daily';
      case 1: return 'weekly';
      case 2: return 'monthly';
      case 3: return 'custom';
      default: return 'daily';
    }
  };

  const loadData = async () => {
    const selectedCard = cardDetails[selectedTab];
    const timeFilter = getTimeFilter();
  
    if (selectedCard) {
      setLoading(true);
      try {
        let currentTime;
        let startTime;
        let endTime;
  
        switch (timeFilter) {
          case 'monthly':
            currentTime = Math.floor(new Date().getTime() / 1000) - (30 * 24 * 60 * 60);
            break;
          case 'weekly':
            currentTime = Math.floor(new Date().getTime() / 1000) - (7 * 24 * 60 * 60);
            break;
          case 'daily':
            currentTime = Math.floor(new Date().getTime() / 1000) - (24 * 60 * 60);
            break;
          case 'custom':
            if (startDate && endDate) {
              // Usar valueOf() en lugar de unix() para obtener milisegundos
              startTime = dayjs(startDate).startOf('day').valueOf(); // En milisegundos
              endTime = dayjs(endDate).endOf('day').valueOf();       // En milisegundos
  
              if (startTime > endTime) {
                console.error('La fecha de inicio no puede ser mayor que la fecha de fin');
                return;
              }
            } else {
              console.error('Ambas fechas deben estar definidas y ser válidas');
              return;
            }
            break;
          default:
            currentTime = Math.floor(new Date().getTime() / 1000) - (5 * 24 * 60 * 60);
        }
  
        const requestPayload = timeFilter === 'custom'
          ? { action: selectedCard.action, startTime, endTime }
          : { action: selectedCard.action, timeFilter: currentTime };
  
        const response = await fetchData(url, context, requestPayload);
        const respData = response;
  
        if (respData && respData.rows) {
          const parsedData = respData.rows.map(item => ({
            value: parseInt(item.total, 10),
            name: item.formatted_date,
            event_type: item.event_type,
            event_type_desc: item.event_type_desc
          }));
  
          setData(parsedData);
        } else {
          console.error('Data format error: response is not an array');
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  
  
  
  

  useEffect(() => {
    loadData();
  }, [selectedTab, timeFilterTab, startDate?.unix(), endDate?.unix()]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={timeFilterTab} onChange={handleTimeFilterChange} centered>
            <Tab label="Diario" />
            <Tab label="Semanal" />
            <Tab label="Mensual" />
            <Tab label="Personalizado" />
          </Tabs>
        </Box>

        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: 'divider', width: '10%' }}
          >
            {cardDetails.map((card, index) => (
              <Tab key={index} label={card.title} {...a11yProps(index)} />
            ))}
          </Tabs>

          <Box sx={{ width: '80%', padding: '16px' }}>
            {timeFilterTab === 3 && (
              <Box sx={{ marginBottom: '20px' }}>
                <DatePicker
                  label='Fecha de inicio'
                  value={startDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setStartDate(newValue);
                    } else {
                      setStartDate(null);
                      format="DD/MM/YYYY"  // Formato de día/mes/año
                    }
                  }}
                />
                <DatePicker
                  label='Fecha fin'
                  value={endDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setEndDate(newValue);
                    } else {
                      setEndDate(null);
                    }
                  }}
                />
              </Box>
            )}

            <Box sx={{ marginTop: '20px' }}>
              {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <AppLoading />
                </Box>
              ) : (
                <GraphReport data={data} type="line" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default ViewEventosClaro;
